import React, { useEffect, useMemo, useState } from "react";
import type { FilterOptions } from "../Filter";
import Filter from "../Filter";
import style from "./Filters.module.css";
import Tag from "../../theming/Tag";

const MINIMUM_OPTIONS_SHOW_SEARCH = 6;

type FilterOptionWithValue<T> = FilterOptions<T> & { value: string };

type FiltersProps<T> = {
  filters: FilterOptions<T>[];
  data: T[];

  // appliedFilters is an object where the key is the reference to the
  // filter and the value the corresponding filter value
  onChange: (newData: T[], appliedFilters: Record<string, string>) => void;
};

const Filters = <T,>({ filters, data, onChange }: FiltersProps<T>) => {
  const [usedFilters, setUsedFilters] = useState<FilterOptionWithValue<T>[]>(
    []
  );

  const filterData = (filtersToApply: typeof usedFilters) => {
    const filteredData = data.filter((row) =>
      filtersToApply.every((f) => f.filter(row, f.value))
    );

    const appliedFilters: Record<string, string> = usedFilters.reduce(
      (res, filter) => {
        res[filter.label] = filter.value;
        return res;
      },
      {}
    );

    onChange(filteredData, appliedFilters);
  };
  const handleFilterChange =
    (filter: FilterOptions<T>) => (selectedOption: string | undefined) => {
      const filtered = usedFilters.filter((f) => f.label !== filter.label);

      if (selectedOption) {
        filtered.push({ ...filter, value: selectedOption });
      }

      setUsedFilters(filtered);

      filterData(filtered);
    };

  const selectedValuesFilters = useMemo(
    () =>
      usedFilters.reduce(
        (parsedFilters, currentFilter) => ({
          ...parsedFilters,
          [currentFilter.label]: currentFilter.value,
        }),
        {}
      ),
    [usedFilters]
  );

  useEffect(() => {
    filterData(usedFilters);
  }, [data]);

  return (
    <div className={style.container}>
      <div className={style.filter_tags}>
        {usedFilters
          .sort((fa, fb) => fb.label.localeCompare(fa.label))
          .map((filter) => (
            <Tag
              key={filter.label}
              text={
                <>
                  {filter.label}: <span>{filter.value}</span>
                </>
              }
              onClose={() => handleFilterChange(filter)(undefined)}
            />
          ))}
      </div>

      {filters.map((f) => (
        <Filter
          onChange={handleFilterChange(f)}
          key={f.label}
          {...f}
          selectedValue={selectedValuesFilters[f.label]}
          showSearch={f.options.length >= MINIMUM_OPTIONS_SHOW_SEARCH}
        />
      ))}
    </div>
  );
};

export default Filters;
