import { Button, Icon, Tag as BaseTag } from "@blueprintjs/core";
import React from "react";
import SdColors from "../../../styles/colors";

interface Props {
  text: React.ReactNode;
  onClose?: () => void;
}

const Tag: React.FunctionComponent<Props> = ({ text, onClose }) => {
  return (
    <BaseTag
      round
      style={{ backgroundColor: SdColors.PRIMARY700, paddingLeft: 17 }}
    >
      {text}
      <Button minimal onClick={onClose}>
        {" "}
        <Icon icon="cross" style={{ color: "white" }} />
      </Button>
    </BaseTag>
  );
};

export default Tag;
